const emailReg = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const emailEmptyMessage = 'Veuillez renseigner votre adresse email';
const emailFormatErrorMessage = 'Adresse email invalide';

export const validateEmail = (value) => emailReg.test(value);

export const emailErrorMessage = (input, isRequired) => {
    let message = '';

    if (input.value.length === 0 && isRequired) {
        message = emailEmptyMessage;
    } else if (!validateEmail(input.value)) {
        message = emailFormatErrorMessage;
    }

    return message;
};
