const partnersLoaded = [];

export function checkConsentById(id) {
    let groupIDs;

    try {
        groupIDs = window.OptanonActiveGroups.split(',');

        return groupIDs.indexOf(id) >= 0;
    } catch (error) {
        return false;
    }
}

export function removeParameters(url, paramsToRemove) {
    const params = new URLSearchParams(url);

    paramsToRemove.forEach((param) => {
        params.delete(param);
    });

    return decodeURIComponent(params.toString());
}

export function checkConsent(event) {
    const id = event.id;
    const label = event.label;
    const callback = event.callback;

    try {
        if (checkConsentById(id) && partnersLoaded.indexOf(label) === -1) {
            partnersLoaded.push(label);
            callback();
        }
    } catch (error) {
        console.warn('Erreur partenaire optanon/oneTrust : ' + label);
    }
}

export function waitConsent(config) {
    window.addEventListener('load', () => {
        checkConsent(config);
    });
}
