import { formatAmount } from './utils.js';

export default function getUserEligibleCagnotte() {
    const userCommonInformation = JSON.parse(sessionStorage.getItem('userCommonInformation'));

    if (
        userCommonInformation &&
        (userCommonInformation.premiumCardPotAmount || userCommonInformation.premiumCardPotAmount === 0)
    ) {
        return `eligible|${formatAmount(userCommonInformation.premiumCardPotAmount)}\u20ac`;
    }
    return 'non_eligible';
}
