const switchClassInit = () => {
    const buttonsSwitch = document.querySelectorAll('[data-switch]');

    buttonsSwitch.forEach((buttonSwitch) => {
        const switchClass = buttonSwitch.dataset.switchClass || 'js-active';
        const originText = buttonSwitch.querySelector('.label')
            ? buttonSwitch.querySelector('.label').textContent
            : null;
        const switchText = buttonSwitch.dataset.switchText;
        let target = buttonSwitch.closest(buttonSwitch.dataset.switchTarget);

        if (!target) {
            target = buttonSwitch;
        }

        buttonSwitch.addEventListener('click', () => {
            // on ajoute/retire la classe sur l'élément et sa cible pour jouer avec leur style CSS
            target.classList.toggle(switchClass);

            // si on a un switch-text alors on toggle le texte à afficher
            if (switchText) {
                const label = buttonSwitch.querySelector('.label');
                label.textContent = target.classList.contains('js-active') ? switchText : originText;
            }
        });
    });
};

export default function switchInit() {
    setTimeout(switchClassInit, 200);
}
