import { updateDatalayer } from './tagcommander.js';
import { updateEventID } from './omniture.js';

export default function tagCommanderListener() {
    function listenTrackingClick(event) {
        const trackingName = 'event_id';
        const eventName = event.target.getAttribute('data-tracking-event');
        updateDatalayer(trackingName, eventName);
        updateEventID(event.target);
    }

    function listenShoppingTool() {
        const setAndUseShoppingToolData = (dataValue) => {
            sessionStorage.setItem('TC_SHOPPING_TOOL_DATA', dataValue);
            updateDatalayer('product_shoppingtool', dataValue);
        };

        const handleRedirectionSeoLink = () => {
            if (window.location.search.indexOf('dartycid') >= 1) {
                setAndUseShoppingToolData('campagneexterne');
            }
        };

        const handleClickOnDataShoppingTool = () => {
            $(document).on('click', '[data-tc-shoppingtool] a, [data-tc-shoppingtool] button', (event) => {
                const shoppingToolAttr = $(event.currentTarget)
                    .closest('[data-tc-shoppingtool]')
                    .attr('data-tc-shoppingtool');
                setAndUseShoppingToolData(shoppingToolAttr);
            });
        };

        const getShoppingToolData = sessionStorage.getItem('TC_SHOPPING_TOOL_DATA');
        if (getShoppingToolData == null) {
            setAndUseShoppingToolData('aucunshoppingtool');
        } else {
            updateDatalayer('product_shoppingtool', getShoppingToolData);
        }
        handleRedirectionSeoLink();
        document.addEventListener('click', (event) => {
            if (event.target.matches('[data-tc-shoppingtool] a, [data-tc-shoppingtool] button')) {
                handleClickOnDataShoppingTool(event);
            }
            if (event.target.matches('[data-tracking-click]')) {
                listenTrackingClick(event);
            }
        });
    }

    listenShoppingTool();
}
