const btnAttrClickCollect = '.js-add-clickcollect-btn';
const addClickCollectForm = '.js-add-clickcollect-form';
const addClickCollectPopin = 'add-clickcollect-popin';
let iframeUrl;

const getLeadFormanceUrl = () => {
    let url = '/webapp/wcs/stores/controller/DartyInfosMagasin?id=';
    if (document.body.getAttribute('data-avec-refonte-leadformance')) {
        url = '/nav/extra/popup/affiche_magasin?codeStore=';
    }

    return url;
};

const listenAddClickCollect = () => {
    document.addEventListener('click', (event) => {
        // Trouver l'élément le plus proche qui correspond au sélecteur
        const element = event.target.closest(btnAttrClickCollect);

        // Continuer seulement si un élément correspondant a été trouvé
        if (element) {
            const leadformanceUrl = getLeadFormanceUrl();
            const basketUpdate = element.getAttribute('data-basket-update');
            const codics = element.getAttribute('data-xsell-codic');

            event.preventDefault();

            fetch('/nav/extra/basket_add/json?mode=checkMagasin', {
                method: 'POST'
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        element.closest(addClickCollectForm).submit();
                    } else {
                        iframeUrl = `/nav/extra/popup/choix_retrait_magasin?from=catalogue&codics=${codics}&basketUpdate=${basketUpdate}&leadformanceUrl=${leadformanceUrl}`;
                        document
                            .getElementById(addClickCollectPopin)
                            .querySelector('iframe')
                            .setAttribute('src', iframeUrl);
                        MicroModal.show(addClickCollectPopin); // Assurez-vous que MicroModal est compatible ou réécrivez cette partie sans jQuery
                    }
                });
        }
    });
};

export default function addClickCollectInit() {
    listenAddClickCollect();
}
