export default function getProfiles(data) {
    let profiles = [];
    const orderedProfiles = ['d+', 'dmax', 'f', 'f+', 'fone'];
    let type;

    if (data.dartyMaxSubscription && data.dartyMaxSubscription.status === 'ACTIVE') {
        profiles.push('dmax');
    }

    if (data.cards) {
        data.cards.forEach((el) => {
            switch (el.cardType) {
                case 'FNAC':
                    type = 'f';
                    break;
                case 'FNAC_PLUS':
                    type = 'f+';
                    break;
                case 'FNAC_ONE':
                    type = 'fone';
                    break;
                case 'DARTY_PLUS':
                    type = 'd+';
                    break;
                default:
            }
            profiles.push(type);
        });
    }
    const commonProfiles = orderedProfiles.filter((value) => profiles.includes(value));
    profiles = commonProfiles;

    if (profiles.length === 0) {
        profiles.push('non_adherent');
    }

    return profiles.join('');
}
