const $toast = document.querySelector('[data-toast]');
const $toastClose = document.querySelector('[data-toast-close]');
let closingTimeout;

const toggleToast = (isOpening, type) => {
    $toast.classList.toggle('js-active', isOpening);
    if (type !== '') {
        $toast.classList.toggle('success', type === 'success');
        $toast.classList.toggle('error', type === 'error');
    }
};

function delayClose(closingDelay) {
    closingTimeout = setTimeout(() => {
        toggleToast(false, '');
    }, closingDelay || 4000);
}

export default function displayToast(options) {
    clearTimeout(closingTimeout);

    $toast.querySelector('[data-toast-message]').innerHTML = options.message;
    toggleToast(true, options.type);

    delayClose();

    $toastClose.addEventListener('click', () => {
        toggleToast(false, '');
        clearTimeout(closingTimeout);
    });
}
