import earlybirdsInit from './earlybirds.js';

let selectFilter;

const closeDropdown = (filterContainer, dropdownContainer) => {
    window.addEventListener('click', (e) => {
        if (!filterContainer.contains(e.target)) {
            dropdownContainer.classList.remove('is-active');
        }
    });
};

export default function toggleDropDown() {
    let $filterContainer;
    let $dropdownContainer;
    let $ebContainer;
    const $dropdownTriggers = document.querySelectorAll('.eb-filter-dropdown__trigger');
    $dropdownTriggers.forEach((trigger) => {
        trigger.addEventListener('click', () => {
            $filterContainer = trigger.closest('.bloc-earlybird__filter');
            $dropdownContainer = $filterContainer.querySelector('.eb-filter-dropdown__wrapper');
            $ebContainer = trigger.closest('[data-earlybirds]');
            for (let i = 0; i < $dropdownContainer.length; i++) {
                if ($dropdownContainer[i] !== trigger) $dropdownContainer = $dropdownContainer[i];
            }
            $dropdownContainer.classList.add('is-active');
            if ($dropdownContainer.classList.contains('is-active')) {
                selectFilter($dropdownContainer, $ebContainer);
            }
            closeDropdown($dropdownContainer.parentNode, $dropdownContainer);
        });
    });
}

const setActiveFilter = (ebContainer) => {
    const filterBtns = ebContainer.querySelectorAll('.eb-filter-dropdown__check');
    const ebContainerWidgetId = ebContainer.getAttribute('data-earlybirds');
    const pertinentWidgetId = ebContainer.getAttribute('data-earlybirds-pertinent');
    let filterWidgetId;
    filterBtns.forEach((filterBtn) => {
        if (filterBtn.getAttribute('data-pertinent') && !filterBtn.getAttribute('data-earlybirds-pertinent').length) {
            filterBtn.setAttribute('data-earlybirds-pertinent', pertinentWidgetId);
        }
        filterBtn.classList.remove('is-active');
        filterWidgetId =
            filterBtn.getAttribute('data-earlybirds-top-sells') || filterBtn.getAttribute('data-earlybirds-pertinent');
        if (ebContainerWidgetId === filterWidgetId) {
            filterBtn.classList.add('is-active');
        }
    });
};

const reloadWidget = (dropdownContainer, ebContainer) => {
    const ebSlider = ebContainer.querySelector('.splide__wrapper');
    const allEbContainers = document.querySelectorAll('[data-earlybirds]');
    ebSlider.remove();
    earlybirdsInit(ebContainer);
    for (let i = 0; i < allEbContainers.length; i++) {
        if (allEbContainers[i] !== ebContainer) allEbContainers[i].removeAttribute('data-is-target');
    }
    ebContainer.setAttribute('data-is-target', true);

    dropdownContainer.classList.remove('is-active');
    $(ebContainer).parent().removeClass('loader');
    setActiveFilter(ebContainer);
};

selectFilter = (dropdownContainer, ebContainer) => {
    let currentWidgetId;
    let topSellsWidgetId;
    const pertinentWidgetId = ebContainer.getAttribute('data-earlybirds-pertinent');
    const $filterItems = ebContainer.querySelectorAll('.eb-filter-dropdown__check');
    $filterItems.forEach((el) => {
        (function () {
            el.addEventListener('click', function () {
                if (!el.classList.contains('is-active')) {
                    if (el.getAttribute('data-earlybirds-top-sells')) {
                        topSellsWidgetId = el.getAttribute('data-earlybirds-top-sells');
                        currentWidgetId = topSellsWidgetId;
                    }
                    if (el.getAttribute('data-earlybirds-pertinent')) {
                        currentWidgetId = pertinentWidgetId;
                    }
                    ebContainer.setAttribute('data-earlybirds', currentWidgetId);
                    if (ebContainer.getAttribute('data-earlybirds') === currentWidgetId) {
                        reloadWidget(dropdownContainer, ebContainer);
                    }
                }
            });
        })();
    });
};
