import { splideInit } from './splide.js';

export default function filterProductsSlider() {
    const $filterTriggers = document.querySelectorAll('.codic-filter-dropdown__trigger');

    function calcReviewScore($productSlider) {
        const $productsListItem = $productSlider.querySelectorAll('.product-splide');
        let reviewScore = 0;
        for (let i = 0; i < $productsListItem.length; i++) {
            reviewScore += Number($productsListItem[i].getAttribute('data-review-score'));
        }
        return reviewScore;
    }

    function sortBy(items, wrapper, prop, order) {
        let startItem;
        let endItem;
        Array.from(items)
            .sort(function (a, b) {
                startItem = Number(a.getAttribute(prop));
                endItem = Number(b.getAttribute(prop));
                switch (order) {
                    case 'asc':
                        return startItem - endItem;
                    case 'desc':
                        return endItem - startItem;
                    default:
                        return false;
                }
            })
            .forEach((el) => {
                wrapper.appendChild(el);
            });
    }

    function filterAction(filterSelected, $productSlider) {
        const filterType = filterSelected.getAttribute('data-sort');
        const $productList = $productSlider.querySelector('.splide__list');
        const $productsListItem = $productSlider.querySelectorAll('.product-splide');
        const $productSplide = $productList.closest('.splide');
        switch (filterType) {
            case 'price_asc':
                sortBy($productsListItem, $productList, 'data-price', 'asc');
                break;
            case 'price_desc':
                sortBy($productsListItem, $productList, 'data-price', 'desc');
                break;
            case 'review_desc':
                sortBy($productsListItem, $productList, 'data-review-score', 'desc');
                break;
            default:
                sortBy($productsListItem, $productList, 'data-default-sort', 'asc');
        }
        splideInit($productSplide);
    }

    function closeDropdown(filterContainer, dropdownContainer) {
        window.addEventListener('click', function (e) {
            if (!filterContainer.contains(e.target)) {
                dropdownContainer.classList.remove('is-active');
            }
        });
    }

    function selectFilter(dropdown, $productSlider) {
        const $filterItems = dropdown.querySelectorAll('.codic-filter-dropdown__check');
        $filterItems.forEach((item) => {
            if (calcReviewScore($productSlider) <= 0 && item.getAttribute('data-sort') === 'review_desc') {
                item.remove();
            }
            item.addEventListener('click', () => {
                for (let i = 0; i < $filterItems.length; i++) {
                    if ($filterItems[i] !== this) $filterItems[i].classList.remove('is-active');
                }
                item.classList.add('is-active');
                filterAction(item, $productSlider);
                dropdown.classList.remove('is-active');
            });
        });
    }

    function toggleDropDown() {
        let $productSlider;
        let $dropdownContainer;
        $filterTriggers.forEach((item) => {
            item.addEventListener('click', () => {
                $productSlider = item.offsetParent.closest('.banner-products, .splide__wrapper');
                $dropdownContainer = $productSlider.querySelector('.codic-filter-dropdown__wrapper');
                $dropdownContainer.classList.toggle('is-active');
                closeDropdown($dropdownContainer.parentNode, $dropdownContainer);
                selectFilter($dropdownContainer, $productSlider);
            });
        });
    }

    toggleDropDown();
}
