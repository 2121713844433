export function checkElementIsVisible(element, marginBeforeElement) {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;
    const vertInView = rect.top <= windowHeight + marginBeforeElement && rect.top + rect.height >= -marginBeforeElement;
    const horInView = rect.left <= windowWidth + marginBeforeElement && rect.left + rect.width >= -marginBeforeElement;
    return vertInView && horInView;
}

export function throttle(delay, callback) {
    let last;
    let timer;
    return function () {
        const context = this;
        const now = +new Date();
        const args = arguments;
        if (last && now < last + delay) {
            clearTimeout(timer);
            timer = setTimeout(function () {
                last = now;
                callback.apply(context, args);
            }, delay);
        } else {
            last = now;
            callback.apply(context, args);
        }
    };
}

export function dynamicallyLoadScript(url, callback, data) {
    const script = document.createElement('script');
    let elem;

    script.src = url;
    if (data) {
        for (elem in data) {
            if (Object.prototype.hasOwnProperty.call(data, elem)) {
                script.setAttribute(elem, data[elem]);
            }
        }
    }
    if (callback) {
        script.onreadystatechange = callback;
        script.onload = callback;
    }

    document.head.appendChild(script);
}

export function detectIsOnScreen($element, callback, marginBeforeElement = 0) {
    document.addEventListener(
        'scroll',
        throttle(500, () => {
            if ($element.hasAttribute('visible')) return;
            if (checkElementIsVisible($element, marginBeforeElement)) {
                $element.setAttribute('visible', true);
                callback();
            }
        })
    );
}

export function getCookie(name) {
    const cookieName = name + '=';
    const cookies = document.cookie.split(';');
    let cookie;
    let i;

    for (i = 0; i < cookies.length; i++) {
        cookie = cookies[i];
        while (cookie.charAt(0) === ' ') cookie = cookie.substring(1, cookie.length);
        if (cookie.indexOf(cookieName) === 0) {
            return decodeURIComponent(cookie.substring(cookieName.length, cookie.length));
        }
    }
    return null;
}

export function userIsConnected() {
    const loginCookie = getCookie('PCKLOGIN');
    const dartyIdsCookie = getCookie('DARTYIDS');
    const firstnameCookie = getCookie('PCKFIRSTNAME');
    const emailCookie = getCookie('PCKEMAIL');

    return (
        loginCookie != null &&
        (loginCookie === '1' || loginCookie === '2') &&
        dartyIdsCookie != null &&
        dartyIdsCookie !== '' &&
        firstnameCookie != null &&
        firstnameCookie !== '' &&
        emailCookie != null &&
        emailCookie !== ''
    );
}

export function formatAmount(amount) {
    const cents = amount.toString().padStart(3, '0');
    const upperDigits = cents.slice(0, -2);
    const underDigits = cents.slice(-2);
    return `${upperDigits},${underDigits}`;
}
